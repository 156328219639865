import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponse,
  GetTasksResponse,
} from '@cdw-selline/common/types';
import {
  UPDATE_TASKS_MUTATION,
  REMOVE_TASKS_MUTATION,
  GET_ADMIN_LIST_TASKS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '../useApolloErrors';
import { Task } from '@cdw-selline/common/types';
import { omit } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import { nonIsExpenseFields, TASK_COST_TYPES } from '@cdw-selline/ui/constants';
import { useTaskGroupDependencies } from '../tasks/useTaskGroupDependencies';

export const useAdminListTasks = (taskGroupId: string, hf) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const taskFieldsNotToSave = ['__typename', 'previousOrder'];
  const taskFieldsNotToInclude = [
    '__typename',
    'skillId',
    'serviceItemId',
    'primaryRoleId',
    'secondaryRoleId',
    'tertiaryRoleId',
  ];

  const emptyTask: Task = {
    order: 0,
    id: '',
    type: '',
    practiceId: '',
    taskId: '',
    name: '',
    estimatorLocation: '',
    units: '',
    category: '',
    comment: '',
    quantity: 0,
    quantityFormula: '',
    quantityMin: 0,
    quantityMax: 0,
    disableQuantity: false,
    disableQuantityFormula: '',
    hours: 0,
    hoursFormula: '',
    disableHours: false,
    disableHoursFormula: '',
    allowTotalHoursAdjustment: true,
    totalHoursNoRounding: false,
    hideHours: false,
    listNames: '',
    listValues: '',
    dropDownIndex: 0,
    dropDownIndexFormula: '',
    noValue: 0,
    yesValue: 0,
    textType: '',
    text: '',
    textFormula: '',
    instructionsText: '',
    textDisabled: false,
    textDisabledFormula: '',
    allowBlankText: false,
    managedServices: false,
    isManagedServicesCostCalculation: false,
    managedServicesFteFormula: '',
    managedServicesArcRrcFormula: '',
    managedServicesTransitionFormula: '',
    costType: '',
    productType: '',
    cost: 0,
    costFormula: '',
    minGrossProfit: 0,
    grossProfit: 0,
    defaultGrossProfit: 0,
    disableCost: false,
    disableCostFormula: '',
    allowMarkup: false,
    alwaysShowOnProposal: false,
    volumePricingEnabled: false,
    volumePricing: [],
    sellPrice: 0,
    sellPriceFormula: '',
    proposalShowEmpty: false,
    proposalNotes: '',
    version: 0,
    tierId: '',
    tier: '',
    sowSectionId: '',
    overtime: false,
    isProjectManagement: false,
    primaryRoleId: '',
    primaryRole: '',
    primaryPercent: 0,
    allowModifyPrimaryRoleRate: false,
    primaryRoleRateOverride: 0,
    primaryRoleRateOverrideFormula: '',
    secondaryRoleId: '',
    secondaryRole: '',
    secondaryPercent: 0,
    allowModifySecondaryRoleRate: false,
    secondaryRoleRateOverride: 0,
    secondaryRoleRateOverrideFormula: '',
    tertiaryRoleId: '',
    tertiaryRole: '',
    tertiaryPercent: 0,
    allowModifyTertiaryRoleRate: false,
    tertiaryRoleRateOverride: 0,
    tertiaryRoleRateOverrideFormula: '',
    hide: false,
    hideShowFormula: '',
    exclude: false,
    vendor: '',
    enrollmentCost: 0,
    enrollmentCostFormula: '',
    enrollmentHours: 0,
    enrollmentRole: '',
    enrollmentHoursFormula: '',
    sprint: '',
    skill: '',
    skillId: '',
    serviceItem: '',
    serviceItemId: '',
    sku: '',
    edc: '',
    isExpense: false,
    isTravelTime: false,
  }

  const {
    loading: tasksLoading,
    error: tasksError,
    data: tasks,
  } = useQuery<{
    getTasks: GetTasksResponse;
  }>(GET_ADMIN_LIST_TASKS_QUERY, {
    variables: {
      offset: 0,
      limit: 1000,
      filters: { taskGroupId: taskGroupId },
      sort: { order: 1 },
    },
    fetchPolicy: 'network-only',
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Tasks!'),
    onCompleted: (data) => {
      validateTaskGroup(data?.getTasks?.tasks);
    },
  });

  const { data: taskGroupDependencies } = useTaskGroupDependencies(taskGroupId);

  const normalizeTask = (task: Task): Task => {
    for (const key in task) {
      switch (typeof task[key]) {
        case 'string':
          task[key] = task[key] || '';
          break;
        case 'number':
          task[key] = task[key] || 0;
          break;
        case 'boolean':
          task[key] = task[key] || false;
          break;
        case 'object':
          task[key] = task[key] ? JSON.stringify(task[key]) : '';
          break;
      }
    }

    return task;
  };

  const validateTaskGroup = (tasks: Task[]) => {
    const taskIds = tasks.map((task) => task.taskId);
    const invalidTasks = [];

    Object.keys(taskGroupDependencies).forEach((key) => {
      const referencedTaskIds = taskGroupDependencies[key];
      referencedTaskIds.forEach((referencedTaskId) => {
        if (!taskIds.includes(referencedTaskId)) {
          const invalidTask = tasks.find((task) => task.id === key);
          if (invalidTask)
            invalidTasks.push(
              `${invalidTask.taskId}: ${invalidTask.name.substring(0, 30)}...`
            );
        }
      });
    });

    if (invalidTasks.length > 0) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.ERROR,
        message: `Task Group contains invalid tasks:\n${invalidTasks.join(
          '\n'
        )}`,
      });
    }
  };

  const getFilteredTask = (task: Task): Task => {
    return normalizeTask(omitDeep(task, taskFieldsNotToInclude) as Task);
  };

  const getTableRows = (tasks: Task[]): Task[] => {
    return tasks.map((task) => getFilteredTask(task));
  };

  const getTableRowArray = (tasks: Task[]): any[] => {
    return tasks.map((task) => {
      let newTask = task
      if (task.costType !== TASK_COST_TYPES.MISC_PER_UNIT) {
        newTask = {
          ...task,
          sellPrice: null,
        }
      }
      const taskArray = Object.values(getFilteredTask(newTask));

      return taskArray;
    });
  };

  const getTableColumns = (tasks: Task[]): string[] => {
    return Object.keys(
      getFilteredTask(tasks.length > 0 ? tasks[0] : emptyTask)
    );
  };

  const addTaskNamedExpressions = (hf, sheetId, sheetName) => {
    tasks.getTasks.tasks.forEach((task, key) => {
      try {
        hf.addNamedExpression(
          'QUANTITY_' + task.taskId,
          `=${sheetName}!$A$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'HOURS_' + task.taskId,
          `=${sheetName}!$B$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'COST_' + task.taskId,
          `=${sheetName}!$C$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'INDEX_' + task.taskId,
          `=${sheetName}!$D$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'HIDE_' + task.taskId,
          `=${sheetName}!$I$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'TASK_TOTAL_HOURS_' + task.taskId,
          `=${sheetName}!$J$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'TASK_TOTAL_COST_' + task.taskId,
          `=${sheetName}!$K$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'TASK_TOTAL_SELL_PRICE_' + task.taskId,
          `=${sheetName}!$L$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'ENROLLMENT_COST_' + task.taskId,
          `=${sheetName}!$M$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'TEXT_' + task.taskId,
          `=${sheetName}!$O$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'SELL_PRICE_' + task.taskId,
          `=${sheetName}!$Q$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'ENROLLMENT_TOTAL_HOURS_' + task.taskId,
          `=${sheetName}!$N$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'ENROLMENT_PM_HOURS_' + task.taskId,
          `=${sheetName}!$U$` + (key + 1),
          sheetId
        );
      } catch (e) {
        console.log('Task Named Expressions', e);
      }
    });
  };

  const addGlobalNamedExpressions = (hf, sheetId, sheetName) => {
    const msContractLength = 12;
    const msRenewal = 0;
    const overtimePercent = 150;
    const pmPercent = 25;

    try {
      hf.addNamedExpression(
        'CALC_TOTAL_HOURS',
        `=SUM(${sheetName}!$J:${sheetName}!$J)`,
        sheetId
      );
      hf.addNamedExpression(
        'CALC_TOTAL_COST',
        `=SUM(${sheetName}!$K:${sheetName}!$K)`,
        sheetId
      );
      hf.addNamedExpression(
        'CALC_TOTAL_SELL_PRICE',
        `=SUM(${sheetName}!$L:${sheetName}!$L)`,
        sheetId
      );
      hf.addNamedExpression(
        'RMS_CONTRACT_LENGTH',
        `${msContractLength}`,
        sheetId
      );
      hf.addNamedExpression('RMS_RENEWAL', `${msRenewal}`, sheetId);
      hf.addNamedExpression('OVERTIME_PERCENT', overtimePercent, sheetId);
      hf.addNamedExpression('PM_PERCENT', pmPercent, sheetId);
      hf.addNamedExpression(
        'TOTAL_ENG_HOURS',
        `=SUM(${sheetName}!$R:${sheetName}!$R)`,
        sheetId
      );
      hf.addNamedExpression(
        'PM_PERCENT_HOURS',
        `=CEILING(${pmPercent / 100}*SUM(${sheetName}!$R:${sheetName}!$R), 1)`,
        sheetId
      );
      hf.addNamedExpression(
        'TOTAL_RMS_MONTHLY_SELL_PRICE',
        `=SUM(${sheetName}!$S:${sheetName}!$S)`,
        sheetId
      );
      hf.addNamedExpression(
        'TOTAL_RMS_ONETIME_SELL_PRICE',
        `=SUM(${sheetName}!$T:${sheetName}!$T)`,
        sheetId
      );
      hf.addNamedExpression(
        'TOTAL_ENROLLMENT_HOURS',
        `=SUM(${sheetName}!$N:${sheetName}!$N)`,
        sheetId
      );
      hf.addNamedExpression(
        'TOTAL_ENROLLMENT_COST',
        `=SUM(${sheetName}!$M:${sheetName}!$M)`,
        sheetId
      );
      hf.addNamedExpression(
        'TOTAL_PM_ENROLLMENT_HOURS',
        `=SUM(${sheetName}!$U:${sheetName}!$U)`,
        sheetId
      );
    } catch (e) {
      console.log('Global Named Expressions', e);
    }
  };

  const addOtherNamedExpressions = (hf, sheetId, sheetName) => {
    tasks.getTasks.tasks.forEach((task, key) => {
      try {
        hf.addNamedExpression(
          'OTHER_HOURS_' + task.taskId,
          `=${sheetName}!$B$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_TASK_TOTAL_HOURS_' + task.taskId,
          `=${sheetName}!$C$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_QUANTITY_' + task.taskId,
          `=${sheetName}!$D$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_COST_' + task.taskId,
          `=${sheetName}!$E$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_TASK_TOTAL_COST_' + task.taskId,
          `=${sheetName}!$F$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_SELL_PRICE_' + task.taskId,
          `=${sheetName}!$G$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_TASK_TOTAL_SELL_PRICE_' + task.taskId,
          `=${sheetName}!$H$` + (key + 1),
          sheetId
        );
        hf.addNamedExpression(
          'OTHER_TASK_INDEX_' + task.taskId,
          `=${sheetName}!$I$` + (key + 1),
          sheetId
        );
      } catch (e) {
        console.log('Other Named Expressions', e);
      }
    });
  };

  const addNamedExpressions = (
    hf,
    sheetId,
    taskSheetName,
    otherSheetName,
    globalSheetName
  ) => {
    const namedExpressionsAdded = Boolean(
      hf.getAllNamedExpressionsSerialized().length
    );

    if (namedExpressionsAdded) {
      return;
    }

    addTaskNamedExpressions(hf, sheetId, taskSheetName);
    addGlobalNamedExpressions(hf, sheetId, globalSheetName);
    addOtherNamedExpressions(hf, sheetId, otherSheetName);
  };

  const [
    updateTasks,
    { loading: updateTasksLoading, error: updateTasksError, client },
  ] = useMutation<{ updateTasks: DefaultMutationResponse }>(
    UPDATE_TASKS_MUTATION,
    {
      onError: (error) => {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.ERROR,
          message: `Failed to save tasks: ${error.message}`,
        });
      },
      onCompleted: (data) => {
        if (data.updateTasks.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved tasks',
          });
        }

        client.refetchQueries({
          include: [GET_ADMIN_LIST_TASKS_QUERY],
        });
      },
    }
  );

  const [
    removeTasks,
    {
      loading: removeTasksLoading,
      error: removeTasksError,
      client: removeTasksClient,
    },
  ] = useMutation<{ removeTasks: DefaultMutationResponse }>(
    REMOVE_TASKS_MUTATION,
    {
      onError: (error) => {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.ERROR,
          message: `Failed to remove tasks: ${error.message}`,
        });
      },
      onCompleted: (data) => {
        if (data.removeTasks.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed tasks',
          });
        }

        removeTasksClient.refetchQueries({
          include: [GET_ADMIN_LIST_TASKS_QUERY],
        });
      },
    }
  );

  const handleTasksSave = (tasks: Task[]) => {
    let noChanges = true;

    if (tasks.length > 0) {
      noChanges = false;

      updateTasks({
        variables: {
          params: tasks.map((task) => {
            task = omit(task, taskFieldsNotToSave);
            return task?.isExpense
              ? {
                  ...task,
                  ...nonIsExpenseFields,
                }
              : task;
          }),
        },
      });
    }

    if (noChanges) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.WARNING,
        message: 'No changes to save!',
      });
    }
  };

  const handleTaskDelete = (taskIds: string[]) => {
    if (taskIds.length === 0) {
      return;
    }

    removeTasks({
      variables: {
        ids: taskIds,
      },
    });
  };

  const getNewTaskId = (tasksToSearch: Task[]) => {
    if (tasksToSearch.length > 0) {
      const maxTaskId = tasksToSearch.reduce((maxTaskId, task) => {
        return Number(task.taskId) > maxTaskId
          ? Number(task.taskId)
          : maxTaskId;
      }, 0);

      return String(maxTaskId + 1);
    }

    const maxTaskId = tasks.getTasks.tasks.reduce((maxTaskId, task) => {
      return Number(task.taskId) > maxTaskId ? Number(task.taskId) : maxTaskId;
    }, 0);

    return String(maxTaskId + 1);
  };

  return {
    tasks: getTableRows(tasks?.getTasks?.tasks ?? []),
    tasksLoading,
    tasksError,
    tableData: getTableRowArray(tasks?.getTasks?.tasks ?? []),
    tableColumns: getTableColumns(getTableRows(tasks?.getTasks?.tasks ?? [])),
    updateTasksLoading,
    updateTasksError,
    removeTasksLoading,
    removeTasksError,
    handleTasksSave,
    getNewTaskId,
    handleTaskDelete,
    addNamedExpressions,
    taskFieldsNotToSave,
    emptyTask,
    taskGroupDependencies,
  };
};
