import { Practice } from '@cdw-selline/common/types';

export const inPractices = (
  practiceId: string,
  currentPractices: Practice[]
) => {
  return currentPractices.some(
    (practice: Practice) => practice.id === practiceId
  );
};
