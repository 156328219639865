import { gql } from '@apollo/client';

export const GET_TASKS_QUERY = gql`
  query GetTasks($filters: MongoFilter, $limit: Int, $offset: Int, $sort: MongoFilter) {
    getTasks {
      count(filters: $filters)
      tasks(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
        category
        comment
        cost
        costType
        estimatorLocation
        edc
        dropDownIndex
        dropDownIndexFormula
        defaultGrossProfit
        excludeToggle
        exclude
        grossProfit
        hours
        id
        lastReviewedOn
        lastReviewedBy
        listNames
        listValues
        minGrossProfit
        name
        noValue
        yesValue
        version
        updatedAt
        units
        type
        totalHours
        technologyName
        taskType
        taskId
        taskGroups
        taskGroupName
        totalHours
        type
        tierId
        tier
        units
        taskGroupId
        subType
        sowSectionId
        sku
        #secondaryRoleName
        secondaryRoleId
        secondaryRole
        secondaryPercent
        #tertiaryRoleName
        tertiaryRoleId
        tertiaryRole
        tertiaryPercent
        renderIndex
        rate
        quantityMin
        quantityMax
        quantityFormula
        quantity
        proposalShowEmpty
        proposalNotes
        #primaryRoleName
        primaryRoleId
        primaryRole
        primaryPercent
        practiceId
        phases {
          taskPercent
          name
        }
        overtime
        noValue
        order
        name
        managedServices
        listValues
        listNames
        list {
          values
          selectedIndex
          names
        }
        lastReviewedOn
        lastReviewedBy
        isProjectManagement
        id
        hoursFormula
        hours
        hideShowFormula
        hide
        grossProfit
        fixedFeeTotalHours
        fixedFeePrice
        excludeToggle
        exclude
        edc
        dropDownIndexFormula
        dropDownIndex
        disableQuantityFormula
        disableCost
        disableCostFormula
        disableHoursFormula
        disableQuantity
        disableHours
        custom
        createdBy
        createdAt
        costType
        costFormula
        cost
        comment
        category
        alwaysShowOnProposal
        allowMarkup
        allowAdditionalGPMargin
        volumePricing {
          minQuantity
          maxQuantity
          cost
        }
        volumePricingEnabled
        textDisabled
        textDisabledFormula
        textFormula
        text
        allowBlankText
        instructionsText
        vendor
        enrollmentCost
        enrollmentCostFormula
        enrollmentHours
        enrollmentRole
        enrollmentHoursFormula    
        sprint
        skill
        textType    
        status
        originalTaskId
        inTestingTaskId
        isExpense
      }
    }
  }
`;

export default GET_TASKS_QUERY;
