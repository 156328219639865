import {
  CiscoAnnuity,
  CiscoQuote,
} from '@cdw-selline/common/types';

export const getCiscoAnnuityActiveQuote = (
  ciscoAnnuity: CiscoAnnuity
): CiscoQuote => {
  return (
    ciscoAnnuity?.ciscoQuotes?.find((obj) => {
      return obj?.id === ciscoAnnuity.activeCiscoQuote;
    }) ?? null
  );
};
