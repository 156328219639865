import { gql } from '@apollo/client';

export const GET_TASK_BY_ID_AND_VERSION_QUERY = gql`
  query getTaskByIdAndVersionQuery($id: ID!, $version: Int!) {
    getTaskByIdAndVersion(id: $id, version: $version) {
      allowMarkup
      allowBlankText
      alwaysShowOnProposal
      category
      comment
      defaultCost
      cost
      costType
      defaultCost
      edc
      excludeToggle
      defaultGrossProfit
      disableQuantity
      disableHours
      disableQuantityFormula
      disableCost
      disableCostFormula
      disableHoursFormula
      dropDownIndex
      dropDownIndexFormula
      hideShowFormula
      quantityFormula
      hoursFormula
      costFormula
      volumePricing {
        minQuantity
        maxQuantity
        cost
      }
      volumePricingEnabled
      hide
      exclude
      grossProfit
      minGrossProfit
      hours
      id
      isProjectManagement
      instructionsText
      lastReviewedOn
      lastReviewedBy
      listNames
      listValues
      managedServices
      name
      noValue
      order
      overtime
      phases {
        taskPercent
        name
      }
      practice
      practiceId
      primaryPercent
      primaryRole
      primaryRoleId
      proposalNotes
      quantity
      quantityMin
      quantityMax
      rate
      secondaryPercent
      secondaryRole
      secondaryRoleId
      tertiaryPercent
      tertiaryRole
      tertiaryRoleId
      taskId
      tierId
      tier
      sku
      sowSectionId
      subType
      taskGroupId
      textDisabled
      textDisabledFormula
      textFormula
      text
      sowSection
      totalHours
      type
      units
      updatedAt
      version
      yesValue
      vendor
      enrollmentCost
      enrollmentCostFormula
      enrollmentHours
      enrollmentRole
      enrollmentHoursFormula
      sprint
      skill
      textType
      margin
      pmPercent
      totalSellPrice
      customServiceDivisorRate
      description
      customServiceCost 
      customServiceSellPrice
      status
      originalTaskId
      inTestingTaskId
    }
  }
`;
