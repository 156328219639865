import { CiscoAnnuityLine } from "@cdw-selline/common/types";

export const useValidateCiscoAnnuityLines = () => {
    const validateCiscoAnnuityLines= async (ciscoAnnuityLines: CiscoAnnuityLine[]) => {
    const errorArray = [];
    let rowIndex = 1;
    for await (const ciscoAnnuityLine of ciscoAnnuityLines) {
      const ciscoAnnuityLineError = await validateCiscoAnnuityLine(ciscoAnnuityLine);
      if (ciscoAnnuityLineError) {
        errorArray.push(`Row ${rowIndex}: ${ciscoAnnuityLineError}`)
      }
      rowIndex++;
    }
    return errorArray;
  }

  const validateCiscoAnnuityLine = async (ciscoAnnuityLine: CiscoAnnuityLine) => {
    if (ciscoAnnuityLine.customerDiscount > ciscoAnnuityLine.cdwDiscount)
    {
       return "Customer discount is greater than CDW discount";
    }

    return '';
  };

  return {
    validateCiscoAnnuityLine,
    validateCiscoAnnuityLines,
  };
}
