import { gql } from '@apollo/client';

export const GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY = gql`
  query GetEstimatorTasksBySiteId(
    $siteId: ID!
    $firstRequest: Boolean
    $taskId: ID
  ) {
    getEstimatorTasksBySiteId(
      siteId: $siteId
      firstRequest: $firstRequest
      taskId: $taskId
    ) {
      allowAdditionalGPMargin
      allowMarkup
      allowBlankText
      allowAdminEdit
      alwaysShowOnProposal
      category
      comment
      costType
      custom
      disableHours
      disableQuantity
      dropDownIndex
      exclude
      hide
      hours
      id
      latestVersion
      listNames
      minGrossProfit
      grossProfit
      name
      noteCount
      overtime
      primaryRole
      proposalDescription
      quantity
      secondaryRole
      sellPrice
      siteId
      taskGroupId
      taskGroupName
      taskId
      text
      textDisabled: currentDisableText
      totalHours
      totalSellPrice
      type
      units
      version
      managedServices
      disableCost
      goalSeekError
      textType
      order
      updatedAt
      allowTotalHoursAdjustment
      totalHoursAdjustment
      hideHours
      isTravelTime
      primaryRoleRateOverride
      primaryRoleRateOverrideFormula
      allowModifyPrimaryRoleRate
      secondaryRoleRateOverride
      secondaryRoleRateOverride
      allowModifySecondaryRoleRate
      isExpense
    }
  }
`;
