export const taskGroupConstants = {
  OPERATORS: [
    { operator: '=', value: 1 },
    { operator: '>', value: 2 },
    { operator: '<', value: 3 },
    { operator: '>=', value: 4 },
    { operator: '<=', value: 5 },
    { operator: '!==', value: 6 },
  ],
  TASK_FIELD: [
    { field: 'Quantity/ListOption', value: 'quantityListOption' },
    { field: 'Hours', value: 'hours' },
    { field: 'Total Hours', value: 'totalHours' },
    { field: 'Sell Price', value: 'sellPrice' },
    { field: 'Total Sell Price', value: 'totalSellPrice' },
  ],
};

export enum TASK_TYPES {
  HOURS = 'Hours',
  COST = 'Cost',
  LIST = 'List',
  YES_NO = 'Yes/No',
  TEXT = 'Text',
  CUSTOM_SERVICE = 'Custom Service',
  PRODUCT = 'Product',
}

export enum TASK_COST_TYPES {
  RECURRING = 'Recurring',
  ONE_TIME = 'One Time',
  FUNDING = 'Funding',
  MISC_PER_UNIT = 'Misc Per Unit'
}

export enum TASK_TEXT_TYPES {
  MULTI_LINE = 'Multi Line',
  SINGLE_LINE = 'Single Line',
}

export enum TASK_TIER_LEVELS {
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
}

export enum TASK_STATUS {
  IN_TESTING='In Testing',
  PUBLISHED='Published',
}

export enum TASK_PRODUCT_TYPES {
  HARDWARE = 'Hardware',
  SOFTWARE = 'Software',
}

export const SOW_SUB_SECTIONS = [
  { label: 'Assumptions', value: 'assumptions' },
  { label: 'Customer Responsibilities', value: 'customerResponsibilities' },
  { label: 'High Level', value: 'highLevel' },
  { label: 'Out of Scope', value: 'outOfScope' },
  { label: 'Scope', value: 'scope' },
];

export const SITE_TABLE_COLUMN_VALUES = [
  { value: 'Site Name' },
  { value: 'Site Address' },
  { value: 'Site Floor' },
  { value: 'Site Quantity' },
  { value: 'Task Id' },
];

export const ADMIN_CONFIG_SETTINGS_KEYS = [
  {category: "Cisco Annuity", key: 'Proposal Template Id', valueType: 'string'},
  {category: "Cisco Annuity", key: 'Change Proposal Template Id', valueType: 'string'},
  {category: "Cisco Annuity", key: 'COF Template Id', valueType: 'string'},
  {category: "Cisco Annuity", key: 'COF Calling Terms Sow Section Id', valueType: 'string'},
  {category: "Cisco Annuity", key: 'COF Terms Sow Section Id', valueType: 'string'},
  {category: "Cisco Annuity", key: 'Cloud Calling Partnumbers',valueType: 'array'},
  {category: "Cisco Annuity", key: 'Cloud Fulfillment COF Request Emails', valueType: 'array'},
  {category: "Cisco Annuity", key: 'Cloud Fulfillment Order Request Emails', valueType: 'array'},
  {category: "Cisco Annuity", key: 'Cloud Fulfillment Team Emails', valueType: 'array'},
  {category: "Cisco Annuity", key: 'Cisco Annuity COF Signed Email Template', valueType: 'string'},
  {category: "Cisco Annuity", key: 'Cisco Annuity Ordered Email Template', valueType: 'string'},
  {category: "Cisco Annuity", key: 'Cisco Annuity Order Denied Template', valueType: 'string'},
  {category: "Cisco Annuity", key: 'Cisco Annuity Request Word COF Email Template', valueType: 'string'},
  {category: "Project", key: 'Base Project URL', valueType: 'string'},
  {category: "Estimator", key: 'SystemProposal ID', valueType: 'string'},
  {category: "Proposal", key: 'Generic Proposal', valueType: 'array'},
].map((config)=>({...config, id: `${config.category}-${config.key}`}));

export const nonIsExpenseFields = {
  costType: TASK_COST_TYPES.ONE_TIME,
  managedServices: false,
  sellPrice: 0,
  grossProfit: 0,
  minGrossProfit: 0,
  allowMarkup: null,
  // cost Type dependents
  skill: null,
  serviceItem: null,
  totalHoursNoRounding: null,
  hideHours: null,
  allowTotalHoursAdjustment: null,
  hours: null,
  hoursFormula: null,
  disableHours: null,
  disableHoursFormula: null,
  
  // managedServices Dependents
  isManagedServicesCostCalculation: null,
  managedServicesFteFormula: null,
  managedServicesArcRrcFormula: null,
  managedServicesTransitionFormula: null,
  alwaysShowOnProposal: null,
  proposalNotes: null,
  enrollmentCost: null,
  enrollmentCostFormula: null,
  enrollmentHours: null,
  enrollmentHoursFormula: null,
  enrollmentRole: null,
  tierId: null,
};