import { ROUTES } from '@cdw-selline/ui/constants';
import { useNavMenu } from '@cdw-selline/ui/hooks';
import {
  Dashboard,
  HomePage,
  NotFoundPage,
  RMSProposalPrintView,
  ProposalPrintView,
  PipelinesTab,
  CustomersCollectionPage,
  LeadsTab,
} from '@cdw-selline/ui/pages';
import {
  ProjectPage,
  PROJECT_PAGE_ROUTES,
  type ProjectPageRoute,
} from './pages/ProjectPage';

const PROJECT_PAGE_ESTIMATOR_ROUTES: ProjectPageRoute[] = [
  {
    path: '/project/:id/estimator/:projectItemId/proposalPrint',
    component: RMSProposalPrintView,
  },
  {
    path: '/project/:id/estimator/:projectItemId/print/:proposalId',
    component: ProposalPrintView,
  },
];

export const useApp = () => {
  const menus = useNavMenu();
  const routes = [
    {
      path: ROUTES.HOME,
      component: Dashboard,
      isPrivate: true,
    },
    {
      path: ROUTES.DASHBOARD,
      component: Dashboard,
      isPrivate: true,
    },
    {
      path: ROUTES.SIGN_IN,
      component: HomePage,
      isPrivate: false,
    },
    {
      path: ROUTES.PIPELINES,
      component: PipelinesTab,
      isPrivate: true,
    },
    {
      path: ROUTES.CUSTOMERS,
      component: CustomersCollectionPage,
      isPrivate: true,
    },
    {
      path: ROUTES.LEADS,
      component: LeadsTab,
      isPrivate: true,
    },
    {
      path: `${ROUTES.PROJECT}/:id`,
      component: ProjectPage,
      isPrivate: true,
    },
    ...PROJECT_PAGE_ROUTES,
    /* 404 page must be last */
    {
      path: '*',
      component: NotFoundPage,
      isPrivate: false,
    },
  ];

  const projectRoutes = [
    ...PROJECT_PAGE_ROUTES,
    ...PROJECT_PAGE_ESTIMATOR_ROUTES,
  ];

  return {
    routes,
    menus,
    projectRoutes,
  };
};
