/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useNonInitialEffect, useOpenState } from '@cdw-selline/ui/hooks';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import {
  DataGrid,
  GridApi,
  GridCellParams,
  GridColumnMenuProps,
  GridFilterModel,
  GridRowId,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  useGridApiRef,
  getGridStringOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridSingleSelectOperators,
  GridFilterOperator,
  GridColumnVisibilityModel,
  GridColDef,
  GridPaginationModel,
  GridCallbackDetails,
  GridRowModelUpdate,
  GridToolbarProps,
} from '@mui/x-data-grid';
import * as React from 'react';
import { escapeRegExp } from '../../notifications-table/NotificationsTable';
import { ErrorOverlay } from '../ErrorOverlay';
import { NoRowsOverlay } from '../NoRowsOverlay';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
import { ErrorBoundary } from 'react-error-boundary';

export interface CollectionsDataTableProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  getRowId?: any;
  editMode?: 'cell' | 'row';
  handleSync?: () => unknown;
  handleAdd?: () => unknown;
  handleDelete?: (args) => unknown;
  handleDeleteRowDetail?: (args) => unknown;
  handleEdit?: (id: string) => unknown;
  handleEditAll?: () => unknown;
  handlePaginationModelChange?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onFilterModelChange?: (args) => unknown;
  handleColumnHeaderClick?: (args) => unknown;
  customRowActions?: any;
  filterModel?: GridFilterModel;
  handleSort?: (args) => unknown;
  loading?: boolean;
  error?: ApolloError;
  onRowClick?: (args) => unknown;
  paginationMode?: 'server' | 'client';
  sortingMode?: 'server' | 'client';
  limit?: number;
  paginationModel?: GridPaginationModel;
  rowCount?: number;
  page?: number;
  handleSave?: (args) => unknown;
  width?: string | number;
  height?: string | number;
  gridMargin?: string | number;
  hideActions?: boolean;
  parent?: string;
  allowExport?: boolean;
  saveOnEditStop?: boolean;
  allowFilter?: boolean;
  density?: 'compact' | 'standard' | 'comfortable';
  dataTestId?: string;
  noDeleteConfirm?: boolean;
  isReadOnly?: boolean;
  allowAddNew?: boolean;
}

interface RowMenuProps {
  api: GridApi;
  id: GridRowId;
  isReadOnly?: boolean;
  handleEdit?: (id: string) => unknown;
  handleSave?: (args) => unknown;
  handleDeleteRowDetail?: (args) => unknown;
  handleDelete?: (args) => unknown;
  customRowActions?: any;
  noDeleteConfirm?: boolean;
}

export interface CustomGridColumnMenuProps
  extends GridToolbarProps,
    CollectionsDataTableProps {
  value: string;
  clearSearch: () => void;
  onChange: (e) => void;
}

export const GridColumnMenu = React.forwardRef<
  HTMLUListElement,
  CustomGridColumnMenuProps
>(function GridColumnMenu(props: CustomGridColumnMenuProps, ref) {
  return (
    <GridToolbarContainer
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid>
        <GridToolbarColumnsButton />
        {props.allowFilter !== false && <GridToolbarFilterButton />}
        <GridToolbarDensitySelector />
        {props.allowExport && <GridToolbarExport />}
        {/* 
        //temporarily remove search feature from datagrid... commenting out so it can easily be brought back once working
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        /> */}
      </Grid>
      <Grid>
        {props.handleEditAll && !props.isReadOnly && (
          <Button
            startIcon={<EditIcon />}
            onClick={props.handleEditAll}
            data-testid={`editall-record-${props.parent}`}
          >
            Edit all records
          </Button>
        )}
        {props.handleAdd && (!props.isReadOnly || props.allowAddNew) && (
          <Button
            startIcon={<AddIcon />}
            onClick={props.handleAdd}
            data-testid={`add-record-${props.parent}`}
          >
            Add record
          </Button>
        )}
        {props.handleSync && !props.isReadOnly && (
          <Button
            startIcon={<SyncIcon />}
            onClick={props.handleSync}
            data-testid={`sync-record-${props.parent}`}
          >
            Sync All Records
          </Button>
        )}
      </Grid>
    </GridToolbarContainer>
  );
});

export const CollectionsDataTable: React.FC<CollectionsDataTableProps> = (
  props
) => {
  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();
  const RowMenuCell = (rowProps: RowMenuProps) => {
    const { api, id } = rowProps;
    const isInEditMode = api.getRowMode(id) === 'edit' && !props.isReadOnly;

    const handleEditClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.startRowEditMode({ id });

      props.handleEdit && props.handleEdit(String(id));
    };

    const handleSaveClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.stopRowEditMode({ id });
      // field param is required by type definition but not needed here since ignored for row editing mode
      const row = api.getRowWithUpdatedValues(id, '');
      props.handleSave(row);
      api.updateRows([{ ...row, isNew: false }]);
    };

    const handleCancelClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.stopRowEditMode({ id, ignoreModifications: true });

      const row = api.getRow(id);
      if (row?.isNew) {
        api.updateRows([{ id, _action: 'delete' } as GridRowModelUpdate]);
      }
    };

    const handleDeleteClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (props.noDeleteConfirm) {
        handleDialogDelete(event);
      } else {
        handleDeleteDialogConfirmOpen();
      }
    };

    const handleDialogDelete = (event) => {
      event.stopPropagation();
      if (props.handleDeleteRowDetail) {
        props.handleDeleteRowDetail(api.getRow(id));
      } else {
        props.handleDelete(id);
      }
      handleDeleteDialogConfirmClose();
    };

    if (isInEditMode) {
      return (
        <div>
          <IconButton size="small" aria-label="save" onClick={handleSaveClick}>
            <SaveIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            aria-label="cancel"
            onClick={handleCancelClick}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        {!props.isReadOnly && props.handleSave && (
          <IconButton size="small" aria-label="edit" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}

        {!props.isReadOnly &&
          (props.handleDelete || props.handleDeleteRowDetail) && (
            <IconButton
              size="small"
              aria-label="delete"
              onClick={handleDeleteClick}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}

        {props.customRowActions && props.customRowActions(id)}

        <DialogConfirm
          title="Delete?"
          isOpen={deleteDialogConfirmOpen}
          handleClose={handleDeleteDialogConfirmClose}
          handleYes={handleDialogDelete}
        >
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogConfirm>
      </div>
    );
  };
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<GridRowsProp>(props.rows ?? []);
  const [colState, setColState] = React.useState<GridColDef[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({});
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(
    props.filterModel ?? {
      items: [],
    }
  );

  React.useEffect(() => {
    setRows(props.rows)
  }, [props.rows])
  React.useEffect(()=>{
    setFilterModel(props.filterModel??{items:[]})
  },[props.filterModel])
  const onColumnVisibilityModelChange = (columnVisibilityModel: GridColumnVisibilityModel) => {
    setColumnVisibilityModel(columnVisibilityModel);
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = props.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row?.[field]?.toString?.());
      });
    });
    setRows(filteredRows);
  };

  const filterStringOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains', 'startsWith', 'endsWith'].includes(value)
  );

  const filterNumericOperators = getGridNumericOperators().filter(({ value }) =>
    ['=', '>=', '<='].includes(value)
  );

  const filterDateOperators = getGridDateOperators().filter(({ value }) =>
    ['is', 'onOrAfter', 'onOrBefore'].includes(value)
  );

  const filterSelectOperators = getGridSingleSelectOperators().filter(
    ({ value }) => ['is', 'not'].includes(value)
  );

  const filterBooleanOperators = getGridBooleanOperators().filter(({ value }) =>
    ['equals'].includes(value)
  );

  const getFilterOperator = (
    type: string | undefined
  ): GridFilterOperator[] => {
    switch (type) {
      case 'number':
        return filterNumericOperators;
      case 'dateTime':
      case 'date':
        return filterDateOperators;
      case 'boolean':
        return filterBooleanOperators;
      case 'singleSelect':
        return filterSelectOperators;
      case 'string':
      default:
        return filterStringOperators;
    }
  };

  React.useEffect(() => {
    const columnVisibilityModel = JSON.parse(
      localStorage.getItem(`${props.parent}-visibility-model`)
    ) ?? {};
    const columns = props.columns.map((c) => {
      if (!Object.keys(c).some((k) => k === 'filterOperators')) {
        c = { ...c, filterOperators: getFilterOperator(c.type) };
      }
      return { ...c };
    });
    columns?.forEach((col) => {
      if (col.field === 'solutionArchitect' || col.field === 'salesRep') {
        columnVisibilityModel[col.field] = false;
      }
    });
    setColState(columns);
    setColumnVisibilityModel(columnVisibilityModel);
  }, [props.columns]);

  useNonInitialEffect(() => {
    localStorage.setItem(
      `${props.parent}-visibility-model`,
      JSON.stringify(columnVisibilityModel)
    );
  }, [columnVisibilityModel]);

  const apiRef = useGridApiRef();

  const handleCellEditStop = (params, event) => {
    if (event.type === 'keydown') {
      return;
    }
    props.handleSave(params.row);
  };

  const handleCellKeyDown = (params: GridCellParams, event) => {
    if (event.key === 'Enter') {
      props.handleSave({ ...params.row, [params.field]: event.target.value });
    }
  };

  const handleFilterChange = (
    model: GridFilterModel,
  ) => {
    if (model.items.length) {
      setFilterModel(model);
      localStorage.setItem(`${props.parent}-filter`, JSON.stringify(model));

      if (props.onFilterModelChange) {
        props.onFilterModelChange(model);
      }
    }
  };

  const actionsCol: GridColDef<any, any, any> = {
    field: 'actions',
    headerName: '',
    renderCell: RowMenuCell,
    sortable: false,
    headerAlign: 'center',
    filterable: false,
    align: 'right',
    disableColumnMenu: true,
    disableReorder: true,
    hideSortIcons: true,
    hideable: false,
  };

  const columns =
    props.columns &&
    !props.hideActions &&
    !props.columns.find((col) => col.field === 'actions')
      ? [...colState, actionsCol]
      : colState;
  return (
    <Grid
      sx={{
        height: props?.height ?? 600,
        width: props?.width ?? '100%',
        margin: props?.gridMargin ?? '2em',
        maxHeight: '100%',
        minHeight: '200px',
      }}
    >
    <ErrorBoundary
      FallbackComponent={ErrorOverlay}
    >
      <DataGrid
        getRowId={props?.getRowId}
        style={{ fontSize: '14px', padding: '2em' }}
        rows={rows}
        columns={columns}
        editMode={props?.editMode}
        sx={{
          ".MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeaders > div[role='row']": {
            backgroundColor: '#fff !important',
          },
        }}
        slots={{
          noRowsOverlay: NoRowsOverlay,
          toolbar: GridColumnMenu,
        }}
        slotProps={{
          columnsPanel: {
            sx: {
              '.MuiDataGrid-panelFooter': {
                display: 'none',
              },
            },
          },
          toolbar: {
            apiRef,
            ...props,
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
        loading={props.loading}
        // error={!!props.error}
        onColumnHeaderClick={props?.handleColumnHeaderClick}
        // onColumnVisibilityModelChange={(col) => {
        //   if (!col.isVisible) {
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     !hiddenCols.includes(col.field)
        //       ? setHiddenCols([...hiddenCols, col.field])
        //       : null;
        //   } else if (hiddenCols.includes(col.field)) {
        //     const newColumnState = hiddenCols.filter((val) => {
        //       return val !== col.field;
        //     });
        //     setHiddenCols(newColumnState);
        //   }
        // }}
        onSortModelChange={props?.handleSort}
        onRowClick={!props?.isReadOnly && props?.onRowClick}
        onRowEditStop={props.saveOnEditStop && handleCellEditStop}
        onCellEditStop={props.saveOnEditStop && handleCellEditStop}
        onCellKeyDown={props.saveOnEditStop && handleCellKeyDown}
        pagination
        paginationMode={props.paginationMode ?? 'client'}
        sortingMode={props.sortingMode ?? 'client'}
        // paginationModel={[props?.page, props?.limit].every(prop => typeof prop === 'number')? { page: props.page, pageSize: props.limit }: undefined}
        paginationModel = {props.paginationModel}
        onPaginationModelChange={props?.handlePaginationModelChange || undefined}
        rowCount={props?.rowCount}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        filterMode="server"
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        hideFooterSelectedRowCount={true}
        // pageSizeOptions={[25, 50]} // not working for some reason
        initialState={{ density: props.density ?? 'standard'}}
      />
    </ErrorBoundary>
    </Grid>
  );
};
